body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.unavailable-container {
   height: calc(100vh - 140px);
}

.unavailable-container > div > p {
  font-weight: 500;
}

.notConnected-container {
  height: calc(100vh - 140px);
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all .2s;
  position: absolute;
  color: white;
  font-weight: bold;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-green {
  background-color: rgb(6 121 98);
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}

.btn-green::after {
  background-color: rgb(6 121 98);
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }

  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

.customBtn {
  border: none;
  outline: none;
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(6 121 98 / 30%) !important;
  border: none;
  outline: none;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent !important;
  cursor: default !important;
}

